.breathing-exercise-screen {
  height: 100vh;
}
.breathing-exercise-screen-content-container {
  padding: 0 15px;
}

.image-placeholder-container {
  width: 100%;
  margin-top: 60px;
}

/*  */
.question-container {
  margin-top: 80px;
  border: 1px solid black;
  border-radius: 28px;
}

.question-container > .question-container-header {
  /* margin-bottom: 20px; */
  padding: 20px;
}

/* .question-container-header {
} */

.question-container-body {
  /* margin-top: 20px; */
}

.question-container-body > .question-container-answer {
  width: 100%;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid black;
}
