.home-screen {
  padding: 0 15px;
  height: 100vh;
}

.home-screen-header {
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.home-screen-header > p {
  font-weight: bold;
  font-size: var(--chakra-fontSizes-4xl);
}

.home-screen-header > .header-icon {
  height: 40px;
  width: 40px;
}
