.breathing-exercise-screen-header {
  justify-content: end;
  height: 50px;
  border-bottom: 1.5px solid black;
}

.breathing-exercise-screen-header > p {
  color: red;
  padding: 0 15px;
}
