.grayBox {
  height: 140px;
  width: 100%;
  background: #e6e6e6;
}

.header {
  font-weight: bold;
  font-size: var(--chakra-fontSizes-4xl);
  margin-top: 20px;
}

.form {
  margin-top: 30px;
  padding: 0 15px;
}

.form > .formField {
  margin-top: 15px;
  color: gray;
  text-align: start;
}

.loginButton {
  background: #424bb2 !important;
  color: white;
  width: 100%;
  margin-top: 15px;
  height: 50px !important;
}

.links {
  text-align: start;
  margin-top: 30px;
}

.links > .link {
  font-weight: bold;
  font-size: var(--chakra-fontSizes-1xl);
  color: #5c65c0;
  margin-top: 15px;
  cursor: pointer;
}
