.finger-print-container {
  align-items: center;
  /* margin-top: 600px; */
  position: absolute;
  bottom: 70px;
  width: calc(100% - 30px);
}

.finger-print-container > p {
  color: gray;
  font-size: 18px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.finger-print {
  height: 80px;
  width: 80px;
}

.finger-print-animation {
  animation-name: stretch;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes stretch {
  100% {
    transform: scale(1.2);
  }
}
